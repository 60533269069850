<template>
  <div>
    <v-btn
      outlined
      v-if="user.transaction_pin_set == true"
      :loading="loading"
      block
      @click="answer == '' ? $toast.error('Please set security question and answer first.') : sendOtp()"
      large color="primary">
      Change PIN
    </v-btn>

    <v-btn
      outlined
      v-else
      block
      @click="dialog = true"
      large color="primary">
      Set Your PIN
    </v-btn>

    <v-dialog v-model="dialog" width="400">
      <v-card class="pa-14">
        <div class="text-center">
          <h4>Set Your PIN</h4>
          <p>Enter a 4-digit PIN below.</p>
          <PincodeInput v-model="form.transaction_pin" :secure="true" />
        </div>
          <v-btn :loading="loading" @click="form.transaction_pin ? setPIN() : $toast.error('Please enter your 4 digit PIN')" large text class="mt-10 primary" block>Set PIN</v-btn>

      </v-card>
    </v-dialog>

     <v-dialog v-model="dialog2" width="400">
      <v-card class="pa-14">
        <div class="">
          <!-- <h4 class="mb-8">Change PIN</h4>
          <p>Enter your old PIN.</p>
          <PincodeInput v-model="old_pin" :secure="true" />

          <p class="mt-8">Enter your new PIN.</p>
          <PincodeInput v-model="new_pin" :secure="true" /> -->
          <div>
            <div class="text-h5 font-weight-medium primary--text">Change Transaction PIN</div>
          </div>
          <v-form class="mt-8" ref="form" lazy-validation>
            <label class="primary--text text-body-1">Old PIN</label>
            <v-text-field
              type="password"
              dense
              outlined
              oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4)"
              v-model="form.old_pin"
              required
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>

            <label class="primary--text text-body-1">New PIN</label>
            <v-text-field
              type="password"
              dense
              outlined
              v-model="form.transaction_pin"
              required
              oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4)"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <label class="primary--text text-body-1">Re-enter New PIN</label>
            <v-text-field
              type="password"
              dense
              outlined
              v-model="form.pin_confirmation"
              oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4)"
              required
              :rules="[
                (v) => !!v || 'Repeat your new PIN', 
                (form.transaction_pin && form.transaction_pin == form.pin_confirmation)  || 'PIN confirmation does not match'
              ]"
            ></v-text-field>
            <label class="primary--text text-body-1">OTP <small>(Check your email for OTP)</small></label>
            <v-text-field
              type="password"
              dense
              outlined
              v-model="form.otp"
              required
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <label class="primary--text text-body-1">Secret Question</label>
            <v-text-field
              outlined
              dense
              v-model="answer"
              readonly
              required
            ></v-text-field>
            <label class="primary--text text-body-1">Answer to your secret question</label>
            <v-text-field
              outlined
              dense
              v-model="form.secret_answer"
              required
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-btn large block outlined color="primary" @click="dialog2 = false">Cancel</v-btn>
              </v-col>
              <v-col>
                <v-btn :loading="loading" @click="$refs.form.validate() ? changePin() : null" large text class="primary" block>Change PIN</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import PincodeInput from 'vue-pincode-input'
import {mapGetters} from 'vuex'
export default {
  components: {
    PincodeInput
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      loading: false,
      form: {},
      answer: '',
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  },

  mounted() {
    this.secretAnswer()
    this.$root.$on('questionSet', () => {
      this.secretAnswer()
    })
  },

  methods:  {
    ...mapActions('transfer', [
      'settransactionpin',
      'updatetransactionpin',
      'sendchangepinotp',
    ]),

    ...mapActions('auth', [
      'secretanswer'
    ]),

    async setPIN() {
      this.loading = true
      this.form.user_id = this.user.id
      try {
        let response  = await this.settransactionpin(this.form)
        this.loading  = false
        this.dialog   = false
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async changePin() {
      this.loading = true
      this.form.user_id = this.user.id
      try {
        let response  = await this.updatetransactionpin(this.form)
        this.loading  = false
        this.dialog2  = false
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        // this.dialog2 = false
        if (error.response.data.errors) {
          this.$toast.error(error.response.data.errors)
        } else {
          this.$toast.error(error.response.data.message)
        }
      }
    },

    async sendOtp() {
      this.loading = true
      let formData = new FormData()
      formData.append('phone_number', this.user.phone)
      try {
        let response = await this.sendchangepinotp(formData)
        this.$toast.success(response.message)
        this.loading = false
        this.dialog2 = true;
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async secretAnswer() {
      try {
        let response = await this.secretanswer(this.user.id)
        this.answer = response.secret_question.question
      } catch (error) {
        // console.log(error.response)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;

  color: #171b70;
}
p {
  font-family: Poppins;
  font-style: normal;

  font-size: 18px;
  line-height: 27px;

  color: #4b4b4b;
}
small {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #c5b8b8;
}
</style>
