<template>
  <div>
    <div class="text-center">
      <v-avatar size="120">
        <img :src="user.image || require('@/static/assets/avatar.jpg')" alt="" />
      </v-avatar>
      <p class="secondary--text mt-4 text-h5 font-weight-medium">{{ user.name }}</p>
    </div>
    <v-form lazy-validation ref="profile" class="mt-12">
      <v-row justify="center" align="center">
        <v-col cols="12" md="8" class="py-0 my-0">
          <p class="label">First Name</p>
          <v-text-field
            outlined solo
            readonly
            required
            :value="user.name.split(' ').slice(0, -1).join(' ')"
            :rules="[(v) => !!v || 'First name is required']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="8" class="py-0 my-0">
          <p class="label">Last Name</p>
          <v-text-field
            outlined
            readonly solo
            required
            :value="user.name.split(' ').slice(-1).join(' ')"
            :rules="[(v) => !!v || 'First name is required']"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="py-0 my-0" v-if="noBVN.includes(user.bvn)">
          <p class="label">Enter BVN</p>
          <v-text-field
            outlined :readonly="!noBVN.includes(user.bvn)"
            required solo
            :rules="[(v) => !!v || 'BVN is required']" v-model="form.bvn"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="8" class="py-0 my-0" v-if="!noBVN.includes(user.bvn)">
          <v-text-field
            outlined
            solo
            value="BVN updated"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row justify="center" align="center">
        <v-col cols="12" md="8" >
          <v-btn class="primary" dark :disabled="!noBVN.includes(user.bvn)" @click="$refs.profile.validate() ? updateProfile() : null" :loading="loading"  text x-large block>{{ !noBVN.includes(user.bvn) ? 'BVN Verified' : 'Validate BVN' }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      menu1: false,
      loading: false,
      form: {},
      noBVN: [null, ''],
    }
  },
   computed: {
    ...mapGetters('auth', ['user']),
  },

  created() {
    this.form.bvn = this.user.bvn
  },

  methods: {
    ...mapActions('auth', ['getData']),

    async updateProfile() {
      this.form.id = this.user.id;
      this.loading = true;
      try {
        let response = await this.$store.dispatch('auth/addbvn', this.form);
        this.$toast.success(response.message);
        this.loading = false;
        await this.getData();
      }
      catch(err) {
        this.$toast.error(err.response.data.message);
        this.loading = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.profile {
  p {
    font-size: 20px;
  }
  p.label {
    color: #3a3939;
    line-height: 8px;
  }
}
</style>
