<template>
  <div>
    <div class="text-center">
      <v-avatar v-if="!loading" size="120" @click="clickAvatar()">
        <img :src="user.image || require('@/static/assets/avatar.jpg')" alt=""/>
      </v-avatar>
      <span class="my-overlay" v-if="loading">
        <v-progress-circular
          size="30"
          indeterminate
          color="primary"
        ></v-progress-circular>
        Uploading...
      </span>
      <p class="secondary--text mt-4 text-h5 font-weight-medium">{{ user.name }}</p>
      <v-file-input
        v-model="profilepic"
        style="visibility: hidden"
        @change="onImageSelect()"
        id="profilepic"
      >
      </v-file-input>
    </div>
    <v-form lazy-validation ref="profile">
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">First Name</p>
          <v-text-field
            outlined
            readonly solo
            required
            :value="user.name ? user.name.split(' ').slice(0, -1).join(' ') : null"
            :rules="[(v) => !!v || 'First name is required']"
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">Last Name</p>
          <v-text-field
            readonly solo
            outlined
            :value="user.name ? user.name.split(' ').slice(-1).join(' ') : null"
            required
            :rules="[(v) => !!v || 'Last name is required']"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">Phone Number</p>
          <v-text-field
            outlined disabled solo
            v-model="user.phone"
            required
            :rules="[(v) => !!v || 'Phone number is required']"
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">Email</p>
          <v-text-field
            outlined disabled solo
            v-model="user.email"
            required
            :rules="[(v) => !!v || 'Email is required']"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">Date of Birth</p>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                append-icon="event_note"
                outlined solo
                :value="form.dob"
                placeholder="Date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              light
              v-model="form.dob"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="py-0 my-0" cols="12" md="6">
          <p class="label">Gender</p>
          <v-select outlined v-model="form.sex" solo :items="['Male', 'Female']">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="12">
          <a href="javascript:void(0)" style="text-decoration: underline" @click="openModal()">
            referral terms and conditions
          </a>
          <v-text-field
            class="my-0"
            v-model="referral_link"
            readonly solo outlined
            placeholder="Referral code"
            id="referral_code"
          >
            <template v-slot:append>
              <v-btn
                depressed
                color="primary"
                class="my-0 py-0"
                @click="copyRef('referral_code')"
              >Copy</v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col md="6" cols="12" class="py-0 my-0 text-center">
          <v-btn
            class="primary"
            :loading="loading"
            @click="$refs.profile.validate() ? updateProfile() : null"
            text
            x-large
            block
            >Update Profile</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      max-width="700"
      v-model="dialog"
      content-class="dialog-class"
    >
      <v-card>
        <v-card-title class="text-h6 primary white--text">
          TRANSAVE REFERRAL PROGRAM
        </v-card-title>

        <v-card-text class="text-center text-h6 primary--text">Terms and Conditions</v-card-text>

        <v-card-title class="primary--text mt-0 pt-0">
          Please carefully read and understand these terms and conditions (T&C’s) for the TranSave Policy:
        </v-card-title>

        <v-card-text class="text-h6 black--text pb-0">User referral Terms</v-card-text>
        <v-card-text>
          The TranSave referral program allows Transave registered and verified users to earn a promotional
          bonus which is credited into their TranSave wallet when they refer family and friends or
          businesses to download, register and become users of the Transave platform. <br><br>

          The reward criteria are set by TranSave in its sole discretion and are subject to change at
          any time.
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">How to get your referral link</v-card-text>
        <v-card-text>
          <ul>
            <li>
              The user TranSave referral link can be retrieved by clicking on the account settings option
              on the website.
            </li>
            <li>The link can then be copied and sent to the referred.</li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">Referral Categories</v-card-text>
        <div class="pl-6">The TranSave referral policy is divided into two (2) categories</div>
        <v-card-text>
          <ul>
            <li>User Referral (Referring individuals to register and use Transave services).</li>
            <li>Business Referral (Referring businesses to open a business account on Transave).</li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">How to Earn a Referral Bonus on Transave</v-card-text>
        <v-card-text>
          <ul>
            <li>
              TranSave users can earn a referral bonus when they refer people/individuals or businesses
              to use the TranSave platform.
            </li>
            <li>
              The user earns the bonus when the referred person/individual or business have downloaded and
              registered on the mobile app or the website as the case may be;
            </li>
            <li>And have carried out at least a transaction on the platform.</li>
            <li>The referred user/business must register using the referral invite link.</li>
            <li>
              The referring user will be credited the bonus amount immediately after the referred makes a
              transaction.
            </li>
            <li>
              The referring user is credited Five hundred naira (₦500) when they refer an individual and
              Two thousand naira (₦2000) when they refer a business.
            </li>
            <li>
              Promotional bonuses can only be earned for referrals in your country of residence and cannot
              be earned for cross border referrals.
            </li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">Receiving Referral Bonuses</v-card-text>
        <v-card-text>
          <ul>
            <li>The referral bonuses are automatically credited to the referral’s TranSave wallet.</li>
            <li>
              Once the referred person or business transacts on TranSave, the specified bonus amount
              for the category will be credited to the referral.
            </li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">Sharing Referral links</v-card-text>
        <v-card-text>
          <ul>
            <li>
              The TranSave referral link should only be used for personal (Family, Relations, Extended family,
              Friends and well wishers) and non-commercial purpose.
            </li>
            <li>Referral Links must not be published or distributed on commercial sites (e.g youtube etc).</li>
            <li>
              Users are prohibited from spamming anyone with their referral links (e.g mass emails or texting
              people you don’t know).
            </li>
            <li>Users are forbidden to pay influencers to advertise their referral links.</li>
            <li>
              Users who are found breaking the above instructions will lose access to bonuses including losing
              bonuses already earned.
            </li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">Referred Persons/Businesses</v-card-text>
        <v-card-text>
          <ul>
            <li>
              Persons or businesses that were referred can also refer others by obtaining there personalise
              referral link as stated above.
            </li>
            <li>
              A referred person/business can only use one referral link. If a person/business was referred
              by multiple users, only the user that owns the referral link that was actually used to
              create the account will receive the bonus.
            </li>
          </ul>
        </v-card-text>

        <v-card-text class="text-h6 black--text pb-0">Updates to Terms</v-card-text>
        <v-card-text>
          We reserve the right to update this terms and conditions at anytime without prior notice.
        </v-card-text>

        <v-card-text class="text-h6 black--text">
          By clicking continue, you agree to the terms and conditions of the Referral policy.
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn
            text
            color="error"
            @click="closeModal()"
          >Close</v-btn>
          <v-btn
            text
            color="primary"
            @click="closeModal()"
          >Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      menu1: false,
      loading: false,
      profilepic: [],
      imagesrc: null,
      form: {},
      dialog: false,
    }
  },
  created() {
    this.form.dob = this.user.dob;
    this.form.sex = this.user.sex;
  },

  methods: {
    async updateProfile() {
      this.form.id = this.user.id;
      this.loading = true;
      await this.$store
        .dispatch('auth/updateprofile', this.form)
        .then((response) => {
          this.$toast.success(response.message);
          this.loading = false;
        }).catch((err) => {
          this.$toast.error(err.response.data.message);
          this.loading = false;
        })
    },
    clickAvatar() {
      document.getElementById('profilepic').click()
    },
    onImageSelect() {
      if (this.profilepic) {
        const file = this.profilepic;
        this.imagesrc = URL.createObjectURL(file);
        this.uploadProfilePic()
      } else {
        this.imagesrc = null;
      }
    },
    async uploadProfilePic() {
      this.loading = true;
      let formData = new FormData();
      formData.append('profile_image', this.profilepic);
      formData.append('user_id', this.user.id);

      try {
        let response = await this.$store.dispatch('auth/uploadavatar', formData);
          this.$toast.success(response.message);
          this.user.image = response.user.image;
          this.loading = false;
        }
      catch(error) {
        this.$toast.error(error.response.data.message);
        this.loading = false;
      }
    },

    copyRef(id) {
      document.getElementById(id).select();
      document.execCommand('copy');
      this.$toast.success('Referral code copied to clipboard');
    },

    openModal() {
      this.dialog = true
    },

    closeModal() {
      this.dialog = false
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'referral_link']),
  },
}
</script>
<style scoped>
  >>> .dialog-class {
  position: absolute;
  right: 0;
}
</style>
