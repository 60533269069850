<template>
  <div>
    <div class="text-center">
      <v-avatar size="120">
        <img :src="user.image || require('@/static/assets/avatar.jpg')" alt="" />
      </v-avatar>
      <p class="secondary--text mt-4 text-h5 font-weight-medium">{{ user.name }}</p>
    </div>
    <v-card class="settings-card pa-6" elevation="0">
      <v-row>
        <v-col cols="12" class="my-0 py-0"  md="4">
          <change-password/>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12" class="my-0 py-0" md="4">
         <transaction-pin></transaction-pin>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-10">
        <v-col cols="12" class="my-0 py-0"  md="4">
          <v-btn block outlined large color="primary">
            Turn Off
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row class="mt-10">
        <v-col cols="12" class="my-0 py-0" md="4">
         <secret-question></secret-question>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import ChangePassword from './ChangePassword'
import TransactionPin from './TransactionPin'
import SecretQuestion from './SecretQuestion'
import {mapGetters} from 'vuex'
export default {
  components:{
    ChangePassword,
    TransactionPin,
    SecretQuestion
  },
  data() {
    return {
      menu1: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>
<style lang="scss" scoped>
.profile {
  p.head {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
    color: #171b70;
  }
  p {
    font-size: 20px;
  }
  p.label {
    color: #3a3939;
    line-height: 8px;
  }
  p.subheading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #6a6060;
  }
}
.settings-card {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  .s-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
  }
  .s-description{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 25px;
color: #7A7A7A;
  }
}
</style>
