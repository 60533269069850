<template>
  <div>
    <v-container class="mb-10">
      <v-row>
        <v-col md="4">
          <v-card color="primary" class="py-3 px-5 mb-5">
          <v-row>
            <v-col cols="9">
              <div class="text-overline font-weight-medium white--text">
                Account Status
              </div>
              <div v-if="account_type == null" class="text-body-1 font-weight-medium secondary--text">
                fetching data...
              </div>
              <div v-else class="text-h6 font-weight-medium secondary--text">
                {{ account_type }}
              </div>
              <div class="text-overline font-weight-bold white--text">
                {{ Math.round(percentage) + '%' }} Completed
              </div>
            </v-col>
            <v-col cols="3" class="text-center pt-8">
              <v-progress-circular
                size="50"
                width="6"
                color="secondary"
                :value="percentage"
              ><small class="white--text font-weight-bold">{{ Math.round(percentage) + '%' }}</small></v-progress-circular>
            </v-col>
          </v-row>
          </v-card>
          <v-container class="grey lighten-5 rounded-lg">
            <v-list class="grey lighten-5 rounded-lg" nav>
              <v-list-item>
                <v-list-item-content>
                  <v-btn
                    class="mb-5 rounded-lg"
                    id="option1"
                    @click="selectOption('1')"
                    x-large depressed
                    block outlined color="primary"
                    >Profile Settings</v-btn>
                    <v-btn
                    class="mb-5 rounded-lg"
                    id="option2"
                    @click="selectOption('2')"
                    x-large depressed
                    block outlined color="primary"
                    >Add BVN</v-btn
                  >
                  <v-btn
                    class="mb-5 rounded-lg"
                    id="option3"
                    @click="selectOption('3')"
                    x-large depressed
                    block outlined color="primary"
                    >KYC Verification</v-btn
                  >
                  <v-btn
                    class="mb-5 rounded-lg"
                    id="option4"
                    @click="selectOption('4')"
                    x-large depressed
                    block outlined color="primary"
                    >Account Numbers</v-btn
                  >
                  <v-btn
                    class="mb-5 rounded-lg"
                    x-large depressed
                    @click="selectOption('5')"
                    id="option5" outlined color="primary"
                    block
                    >Settings</v-btn
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
        </v-col>
        <v-col>
          <div v-if="profile" class="profile grey lighten-5 pa-5 pa-md-8 pt-8 rounded-lg">
            <profile-settings />
          </div>
          <div v-if="bvn" class="profile grey lighten-5 pa-5 pa-md-8 pt-8 rounded-xl">
            <add-bvn />
          </div>
          <!-- <div v-if="withdraw" class="profile">
            <withdraw-fund/>
          </div>
          <div v-if="bank" class="profile">
            <bank-settings />
          </div> -->
          <div class="profile" v-if="account">
          <account-numbers />
          </div>
          <div class="profile grey lighten-5 pa-5 pa-md-8 pt-8 rounded-xl" v-if="kyc">
          <kyc-verification />
          </div>
          <div v-if="settings" class="profile grey lighten-5 pa-5 pa-md-8 pt-8 rounded-xl">
            <settings/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AddBvn from '@/components/accounts/AddBvn.vue'
// import BankSettings from '@/components/accounts/BankSettings.vue'
import KycVerification from '@/components/accounts/KycVerification.vue'
import AccountNumbers from '@/components/accounts/AccountNumbers.vue'
import ProfileSettings from '@/components/accounts/ProfileSettings.vue'
import Settings from '@/components/accounts/Settings.vue'
// import WithdrawFund from '@/components/accounts/WithdrawFund.vue'
import {mapGetters} from 'vuex'

export default {
  components: { ProfileSettings, AddBvn, Settings, AccountNumbers, KycVerification },
  layout: 'dashboard',
  transition: 'default',
  data() {
    return {
      profile: true,
      bvn: false,
      kyc_level: null,
      account_type: null,
      percentage: 0,
      kyc: false,
      account: false,
      settings: false,
      drawer: true,
        items: [
          { title: 'Home', icon: 'mdi-home-city' },
          { title: 'My Account', icon: 'mdi-account' },
          { title: 'Users', icon: 'mdi-account-group-outline' },
        ],
        mini: false,
    }
  },

  created() {
    this.getKyc()
  },

  mounted() {
    if (this.$route.query.pos_request) {
      this.selectOption('3')
    }
  },
  
  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    selectOption(option) {
      for (var i = 1; i <= 5; i++) {
        document.getElementById('option' + i).classList.remove('active')
      }
      document.getElementById('option' + option).classList.add('active')
      if (option == '1') {
        this.profile = true
        this.bvn = false
        // this.withdraw = false
        // this.bank = false
        this.kyc = false
        this.account = false
        this.settings = false
      } else if (option == '2') {
        this.profile = false
        this.bvn = true
        this.kyc = false
        this.account = false
        this.settings = false
      } else if (option == '3') {
        this.profile = false
        this.bvn = false
        this.kyc = true
        this.account = false
        this.settings = false
      } else if (option == '4') {
        this.profile = false
        this.bvn = false
        this.kyc = false
        this.account = true
        this.settings = false
      } else if (option == '5') {
        this.profile = false
        this.bvn = false
        this.kyc = false
        this.account = false
        this.settings = true
      }
    },

    async getKyc() {
      // this.kycLoading = true
      try {
        let response = await this.$store.dispatch("auth/getkyc", this.user.id)
        this.kyc_level  = response.data
        this.kycPercentage()
        this.accountType()
        // this.kycLoading = false
      } catch (error) {
        // this.kycLoading = false
        console.log(error)
      }
    },

    async accountType() {
      const id = this.kyc_level.user.account_type_id
      try {
        let response = await this.$store.dispatch('auth/accounttype', id)
        this.account_type = response.data.name
      } catch (error) {
        console.log(error)
      }
    },

    async kycPercentage() {
      const id = this.kyc_level.id
      try {
        let response = await this.$store.dispatch('auth/kycpercentage', id)
        this.percentage = response.data
      } catch (error) {
        console.log(error.response)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.name--text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7a7a7a;
}

.card-t {
  border-radius: 5px;
  height: 120px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  background-image: url('../../../static/assets/otp.jpeg');
  color: #ffffff;
  position: relative;
}
.overlay{
  background: #171b70;
  opacity: 0.9;
  position: absolute;
  height: 120px;
  width: 100%;
}

.option-btn {
  background: #f4efe0;
  opacity: 0.9;
  border: 0.5px solid #171b70;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #171b70;
}
.active {
  background: #9a9ccc !important;
  border: 0.5px solid #171b70;
  box-sizing: border-box;
  border-radius: 5px;
}
.v-progress-circular__underlay {
  stroke:rgba(255,255,255,.9);
  z-index: 1;
}
</style>
