<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        class="pa-4"
      >
        <label for=""><small>Reason for KYC update</small></label>
        <v-textarea
          placeholder="Enter the reason for editing your KYC"
          outlined
        ></v-textarea>
        <v-card-actions
          class="justify-center"
        >
        <v-col cols="6">
          <v-btn
            color="grey lighten-3"
            @click="closeDialog()"
            depressed
            block
          >CLOSE</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            @click="sendRequest()"
            :loading="loading"
          >Submit</v-btn>
        </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', [
      'kyceditrequest',
    ]),

    openDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    async sendRequest() {
      this.loading = true
      try {
        let response = await this.kyceditrequest(this.user.id)
        this.loading = false
        this.$toast.success(response.message)
        this.closeDialog()
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    }
  }
}
</script>