<template>
  <div>
    <div class="text-center">
      <v-avatar size="120">
        <img :src="user.image || require('@/static/assets/avatar.jpg')" alt="" />
      </v-avatar>
      <p class="secondary--text mt-4 text-h5 font-weight-medium">{{ user.name }}</p>
    </div>
    <p v-if="kycLoading" class="text-body-1 text-center">
      Update your KYC documents below.
    </p>

    <div v-if="kycLoading">
      <div class="py-5 text-center my-5">
        <mini-loader class="text-center"></mini-loader>
        <p class="primary--text text-body-1">Please wait...</p>
      </div>
    </div>

    <div v-if="!kycLoading">
      <v-stepper
        v-model="kyc_level"
        vertical
      >
        <v-btn
          text
          color="primary"
          block
          @click="$refs.account_types.openDialog()"
        >View Account Types</v-btn>
        <v-stepper-step
          :complete="kyc_level > 1"
          step="1"
        >
          <small class="primary--text">Complete your basic information <span style="font-weight: 500;" v-if="kyc_level > 1">- Completed</span></small>
        </v-stepper-step>

        <v-stepper-content step="1" class="mr-0 pr-2 pl-2">
          <v-form lazy-validation ref="kyc1" class="mt-2">
            <!-- <p class="text-body-1 primary--text mb-8">Some values have been pre-filled from your registration details. click save to continue</p> -->
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.first_name"
                  label="First Name"
                  required
                  readonly=""
                  :rules="[(f) => !!f || 'Please enter your first name']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.middle_name"
                  label="Middle Name"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.last_name"
                  readonly=""
                  label="Last Name"
                  :rules="[(l) => !!l || 'Please enter your last name']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-select
                  v-model="form.sex"
                  label="Gender"
                  :items="[
                    { text: 'Male', value: 'Male' },
                    { text: 'Female', value: 'Female' },
                  ]"
                  :rules="[(f) => !!f || 'This field is required']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.bvn"
                  :readonly="form.bvn !== null"
                  label="BVN"
                  :rules="[(l) => !!l || 'Please enter your BVN']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="form.dob == null" align="center" justify="center">
              <v-col class="py-0 my-0" cols="12" md="6">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="event_note"
                      outlined solo
                      :value="form.dob"
                      placeholder="Date of birth"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    light
                    v-model="form.dob"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-file-input
                  v-model="form.passport"
                  label="Upload Passport Photograph"
                  :rules="[(l) => !!l || 'This field is required']"
                  accept="image/*, application/pdf"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-btn
              class="primary"
              :loading="loading"
              @click="$refs.kyc1.validate() ? verifyDetails() : null"
              text
            >Submit</v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          :complete="kyc_level > 2"
          step="2"
        >
          <small class="primary--text">Upgrade to Classic member <span style="font-weight: 500;" v-if="kyc_level > 2">- Completed</span></small>
        </v-stepper-step>

        <v-stepper-content step="2" class="mr-0 pr-2 pl-2">
          <v-form lazy-validation ref="kyc2" class="mt-2">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.address"
                  label="Address"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-file-input 
                  :label="form.proof_of_address_url ? 'Uploaded' : 'Upload Utility Bill'" 
                  :disabled="kyc ? kyc.proof_of_address_url !== null : null"
                  v-model="form.address_file"
                  :rules="[(f) => !!f || 'This field is required']"
                  accept="image/*, application/pdf"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.mother_maiden_name"
                  label="Mother's maiden name"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.next_of_kin"
                  label="Next of Kin"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="number"
                  v-model="form.next_of_kin_contact"
                  label="Next of kin contact"
                  :rules="[(f) => !!f || 'Please enter a valid phone number']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-autocomplete
                  v-model="form.state_id"
                  :items="states"
                  label="Select State"
                  @change="lgas = []; getLgas()"
                  :loading="loadStates"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-autocomplete
                  type="text"
                  v-model="form.lga_id"
                  label="Select LGA"
                  :items="lgas"
                  :loading="loadLgas"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.city"
                  label="City"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-select
                  v-model="form.id_card_type_id"
                  :items="card_types"
                  label="ID Card Type"
                  :loading="fetchIds"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.id_card_number"
                  label="ID card Number"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-file-input 
                  label="Upload ID" 
                  v-model="form.card_file" 
                  :disabled="kyc ? kyc.id_card_url !== null : null"
                  :rules="[(f) => !!f || 'This field is required']"
                  accept="image/*, application/pdf"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-btn
              class="primary"
              text
              @click="$refs.kyc2.validate() ? ordinaryMember() : null"
              :loading="loading"
            >Submit</v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          :complete="kyc_level > 3 || (kyc) && kyc.country_of_origin_id !== null"
          step="3"
        >
          <small class="primary--text">Upgrade to Premium member <span style="font-weight: 500;" v-if="kyc_level >= 3">{{ kyc_level > 3 ? '- Completed' : kyc_level == 3 && (kyc) && kyc.country_of_origin_id !== null ? '- Pending verification' : '' }}</span></small>
        </v-stepper-step>

        <v-stepper-content :step="(kyc) && kyc_level == 3 && kyc.country_of_origin_id == null ? '3' : ''" class="mr-0 pr-2 pl-2">
          <v-form lazy-validation ref="kyc3" class="mt-2">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.guarantor"
                  label="Guarantor Name"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-text-field
                  type="number"
                  v-model="form.guarantor_contact"
                  label="Guarantor Phone Number"
                  :rules="[(f) => !!f || 'Please enter a valid phone number']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-autocomplete
                  type="text"
                  v-model="form.country_of_residence_id"
                  label="Select Country of residence"
                  :items="countries"
                  :loading="loadCountries"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="8" class="py-0 my-0">
                <v-autocomplete
                  type="text"
                  v-model="form.country_of_origin_id"
                  label="Select Country of Origin"
                  :items="countries"
                  :loading="loadCountries"
                  :rules="[(f) => !!f || 'This field is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-btn
              class="primary"
              text 
              @click="$refs.kyc3.validate() ? premiumAccount() : null" 
              :loading="loading"
            >Submit</v-btn>
          </v-form>
        </v-stepper-content>
        <v-row justify="center" class="mt-4">
          <v-btn
            v-if="$parent.percentage > 10 && is_completed == 1"
            color="primary"
            small
            @click="$refs.update_request.openDialog()"
          ><v-icon size="15" class="mr-1">refresh</v-icon> request KYC update</v-btn>
          <v-btn
            v-else
            color="primary"
            small
            @click="$refs.update_kyc.openDialog(kyc)"
          ><v-icon size="15" class="mr-1">edit</v-icon> Edit KYC</v-btn>
        </v-row>
      </v-stepper>
    </div>
    <account-types ref="account_types"></account-types>
    <update-request ref="update_request"></update-request>
    <update-kyc ref="update_kyc"></update-kyc>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AccountTypes from '@/components/accounts/dialogs/kyc/account_types' 
import UpdateRequest from '@/components/accounts/dialogs/kyc/update_request'
import UpdateKyc from '@/components/accounts/dialogs/kyc/update_kyc'
import MiniLoader from "@/components/MiniLoader.vue";
export default {
  components: {
    MiniLoader,
    AccountTypes,
    UpdateRequest,
    UpdateKyc,
  },

  data() {
    return {
      menu1: false,
      passport: [],
      id_type: "",
      id_pic: [],
      loading: false,
      form: {},
      kyc: null,
      fetchIds: false,
      states: [],
      lgas: [],
      countries: [],
      card_types: [],
      loadStates: false,
      loadLgas: false,
      loadCountries: false,
      kycLoading: false,
      is_completed: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    kyc_level() {
      let level = 1;
      if (this.kyc && this.kyc.passport_url && this.kyc.first_name
        && this.kyc.last_name && this.user.dob && this.user.bvn) {
          level = 2
      }
      
      if (this.kyc && this.kyc.home_address && this.kyc.proof_of_address_url 
      && this.kyc.mother_maiden_name && this.kyc.next_of_kin && this.kyc.next_of_kin_contact
      && this.kyc.state_id && this.kyc.lga_id && this.kyc.city && this.kyc.id_card_number
      && this.kyc.id_card_url && this.kyc.id_card_type_id) {
        level = 3
      }

      if (this.kyc && this.kyc.guarantor && this.kyc.guarantor_contact
      && this.kyc.country_of_residence_id && this.kyc.country_of_origin_id && this.user.account_type === 'Premium') {
        level = 4
      }
      return level
    }
  },
  methods: {
    ...mapActions('auth', [
      'kycstatus'
    ]),

    async verifyDetails() {
      this.loading = true
      let formData = new FormData()
      formData.append('middle_name', this.form.middle_name)
      formData.append('sex', this.form.sex)
      formData.append('bvn', this.form.bvn)
      formData.append('passport_photo', this.form.passport)
      formData.append('id', this.user.id)
      formData.append('first_name', this.form.first_name)
      formData.append('last_name', this.form.last_name)
      formData.append('dob', this.form.dob)
      formData.append('id', this.user.id)
      try {
        let response = await this.$store.dispatch('auth/updatekyc', formData)
        this.loading = false
        this.kyc = Object.assign({}, response.data)
        this.getKyc()
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },
    
    async ordinaryMember() {
      this.loading = true
      let formData = new FormData()
      try {
        formData.append('home_address', this.form.address)
        formData.append('address', this.form.address)
        formData.append('address_file', this.form.address_file)
        formData.append('mother_maiden_name', this.form.mother_maiden_name)
        formData.append('next_of_kin', this.form.next_of_kin)
        formData.append('next_of_kin_contact', this.form.next_of_kin_contact)
        formData.append('state_id', this.form.state_id)
        formData.append('lga_id', this.form.lga_id)
        formData.append('city', this.form.city)
        formData.append('id_card_number', this.form.id_card_number)
        formData.append('card_file', this.form.card_file)
        formData.append('id_card_type_id', this.form.id_card_type_id)
        formData.append('id', this.user.id)
        let response = await this.$store.dispatch('auth/updatekyc', formData)
        this.getKyc()
        this.loading = false
        this.kyc  = Object.assign({}, response.data)
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async premiumAccount() {
      this.loading = true
      let formData = new FormData()
      try {
        formData.append('guarantor', this.form.guarantor)
        formData.append('guarantor_contact', this.form.guarantor_contact)
        formData.append('country_of_residence_id', this.form.country_of_residence_id)
        formData.append('country_of_origin_id', this.form.country_of_origin_id)
        formData.append('id', this.user.id)
        let response = await this.$store.dispatch('auth/updatekyc', formData)
        this.getKyc()
        this.loading = false
        this.kyc = Object.assign({}, response.data)
        if (this.$route.query.pos_request) {
          this.$router.push({ name: 'Pos', query: { premium_kyc: 'yes'} })
        }
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    },

    async getKyc() {
      this.kycLoading = true;
      try {
        let response = await this.$store.dispatch("auth/getkyc", this.user.id);
        this.kyc          = Object.assign({}, response.data);
        this.kycLoading   = false;
        this.$parent.getKyc()
        this.is_completed = response.data.is_completed
      } catch (error) {
        this.kycLoading = false;
        console.log(error);
      }
    },

    async kycStatus() {
      try {
        let response = await this.kycstatus(this.user.id)
        this.isKycCompleted = response.data.state
      } catch (error) {
        console.log(error.response)
      }
    },

    async getStates() {
      this.loadStates = true;
      try {
        let response = await this.$store.dispatch("auth/kycstates");
        response.data.forEach((state) => {
          let states = {};
          (states.value = state.id), (states.text = state.state);
          this.states.push(states);
        });
        this.loadStates = false;
      } catch (error) {
        this.loadStates = false;
        console.log(error);
      }
    },

    async getLgas() {
      this.loadLgas = true;
      try {
        let response = await this.$store.dispatch("auth/kyclgas");
        response.data.filter((lgs) => {
          if (lgs.state_id == this.form.state_id) {
            let lgas = {};
            (lgas.text = lgs.lga), (lgas.value = lgs.id);
            this.lgas.push(lgas);
          }
        });
        this.loadLgas = false;
      } catch (error) {
        this.loadLgas = false;
        console.log(error);
      }
    },

    async getCountries() {
      this.loadCountries = true;
      try {
        let response = await this.$store.dispatch("auth/kyccountries");
        response.data.forEach((country) => {
          let countries = {};
          (countries.value = country.id), (countries.text = country.country);
          this.countries.push(countries);
        });
        this.loadCountries = false;
      } catch (error) {
        this.loadCountries = false;
        console.log(error);
      }
    },

    async idCardTypes() {
      this.fetchIds = true
      try {
        let response = await this.$store.dispatch('auth/getidtypes')
        this.fetchIds = false
        response.data.forEach(card => {
          let cards   = {}
          cards.text  = card.name
          cards.value    = card.id
          this.card_types.push(cards)
        })
      } catch (error) {
        this.fetchIds = false
      }
    }
  },

  mounted() {
    this.getKyc();
    this.kycStatus();
  },

  created() {
    this.getStates();
    this.getLgas();
    this.getCountries();
    this.idCardTypes();
    this.form.user_id     = this.user.id;
    this.form.first_name  = this.user.name.split(" ").slice(0, -1).join(" ");
    this.form.last_name   = this.user.name.split(" ").slice(-1).join(" ");
    this.form.sex         = this.user.sex;
    this.form.bvn         = this.user.bvn;
    this.form.dob         = this.user.dob;
  },
};
</script>
<style lang="scss" scoped>
.profile {
  p.head {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
    color: #171b70;
  }
  p {
    font-size: 20px;
  }
  p.label {
    color: #3a3939;
    line-height: 8px;
  }
  p.subheading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #6a6060;
  }
}
.otp-btn {
  background: #c1a13d !important;
  opacity: 0.9;
  border: 1px solid #171b70;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.otp-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #7a7a7a;
}
</style>
