<template>
  <div>
    <v-btn outlined block @click="dialog = true" large color="primary">
      Change Password
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-14">
        <div class="text-center">
          <div class="text-h5 font-weight-medium primary--text">Change Password</div>
        </div>
        <v-form class="mt-8" ref="form" lazy-validation>
          <label><small class="primary--text">Old Password</small></label>
          <v-text-field
            type="password"
            outlined
            dense
            v-model="form.old_password"
            required
            :rules="[(v) => !!v || 'Old password is required']"
          ></v-text-field>

          <label for=""><small class="primary--text">New Password</small></label>
          <v-text-field
            type="password"
            outlined
            dense
            v-model="form.password"
            required
            :rules="[(v) => !!v || 'New password is required']"
          ></v-text-field>
          <label for=""><small class="primary--text">Repeat Password</small></label>
          <v-text-field
            type="password"
            outlined
            dense
            v-model="form.password_confirmation"
            required
            :rules="[
              (v) => !!v || 'Repeat your new password', 
              (form.password && form.password == form.password_confirmation)  || 'Password confirmation does not match'
            ]"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-btn
                large 
                block 
                outlined 
                color="primary" 
                @click="closeDialog()">Cancel</v-btn>
            </v-col>
            <v-col>
              <v-btn
                large 
                block 
                class="primary" 
                :loading="loading"
                @click="$refs.form.validate() ? changePassword() : null">Change</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    
    <errors-dialog ref="errors" :errors="errors"></errors-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ErrorsDialog from '@/components/pos/dialogs/errorsDialog' 
export default {
  components: {
    ErrorsDialog,
  },

  data() {
    return {
      dialog: false,
      form: {},
      loading: false,
      errors: {},
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', [
      'changepassword',
    ]),

    closeDialog() {
      this.dialog = false
    },

    async changePassword() {
      this.loading = true
      this.form.id = this.user.id
      try {
        let response = await this.changepassword(this.form)
        this.loading = false
        this.$toast.success(response.message)
        this.closeDialog()
        this.$refs.form.reset()
      } catch (error) {
        this.loading = false
        if (error.response.status == 422) {
          this.errors = Object.assign({}, error.response.data.message)
          this.$refs.errors.openDialog()
        } else {
          this.$toast.error(error.response.data.message)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;

  color: #171b70;
}
p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #4b4b4b;
}
small {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #c5b8b8;
}
</style>
