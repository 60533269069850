<template>
  <div>
    <div class="text-center">
      <v-avatar size="120">
        <img
          :src="user.image || require('@/static/assets/avatar.jpg')"
          alt=""
        />
      </v-avatar>
      <p class="secondary--text mt-4 text-h5 font-weight-medium">{{ user.name }}</p>
    </div>
    <v-card class="account-card" elevation="0">
      <!-- <div class="pa-12 pt-8">
        <p class="theading">Wallet Numbers</p>

        <v-card flat class="account-box pa-6">
          <v-row>
            <v-col>
              <h2>1234567890</h2>
            </v-col>
            <v-col class="text-right">
                <v-btn style="background: #E9EAFF;" class="primary--text" rounded text>Copy</v-btn>
            </v-col>
          </v-row>
        </v-card>

      </div>
        <v-divider></v-divider> -->
      <div class="pa-5 pa-md-8 pt-8">
        <p class="theading">Account Numbers</p>
        <v-card
          flat
          class="account-box pa-6 mb-10"
          v-for="i in user.accounts"
          :key="i.id"
        >
          <v-row>
            <v-col>
              <p class="account-name">
                {{ i.account_name }}
              </p>
              <h2>{{ i.account_number }}</h2>
            </v-col>
            <v-col class="text-right">
              <v-btn
                style="background: #e9eaff"
                class="primary--text"
                rounded @click="copyText(i.account_number)"
                text
                >Copy</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      menu1: false,
    }
  },
  methods: {
    async copyText(text) {
      await navigator.clipboard.writeText(text)
      this.$toast.success('Account Number Copied Successfully', 'Success')
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>
<style lang="scss" scoped>
.profile {
  p.head {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
    color: #171b70;
  }
  p {
    font-size: 20px;
  }
  p.label {
    color: #3a3939;
    line-height: 8px;
  }
  p.subheading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #6a6060;
  }
}
.account-card {
  background: #ffffff;
  border-radius: 5px;
  p.theading {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #171b70;
  }
  .account-box {
    background: #f6f6ff;
    border: 1px solid #ccceea;
    box-sizing: border-box;
    border-radius: 5px;
    p.account-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #7a7a7a;
    }
    h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #171b70;
    }
  }
}
</style>
