<template>
  <div>
    <v-btn outlined block @click="dialog = true" large color="primary">
      Secret Question
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-14">
        <div class="text-center">
          <v-btn icon class="float-right" color="primary" @click="closeDialog()"><v-icon>cancel</v-icon></v-btn>
          <div class="text-h5 font-weight-medium primary--text">Set Secret Question</div>
        </div>
        <v-form class="mt-8" ref="form" lazy-validation>
          <p class="text-body-1">Question</p>
          <v-textarea
            outlined
            v-model="form.question"
            required
            :rules="[(v) => !!v || 'This field is required']"
          ></v-textarea>

          <p class="text-body-1">Answer</p>
          <v-text-field
            outlined
            v-model="form.answer"
            required
            :rules="[(v) => !!v || 'This field is required']"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-btn large block class="primary" :loading="loading" @click="$refs.form.validate() ? secretQuestion() : null">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      form: {},
      loading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', [
      'secretquestion'  
    ]),

    closeDialog() {
      this.dialog = false
    },

    async secretQuestion() {
      this.loading = true
      this.form.user_id = this.user.id
      try {
        let response = await this.secretquestion(this.form)
        this.closeDialog()
        this.loading = false
        this.$toast.success(response.message)
        this.$root.$emit('questionSet')
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;

  color: #171b70;
}
p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #4b4b4b;
}
small {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #c5b8b8;
}
</style>
