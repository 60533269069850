<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        class="pa-4"
      >
        <p class="text-center text-h6"><small class="primary--text">Update your KYC using the form below</small></p>
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0 pt-2">
                  <v-text-field
                    type="text"
                    v-model="form.first_name"
                    label="First Name"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    v-model="form.middle_name"
                    label="Middle Name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    v-model="form.last_name"
                    label="Last Name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-select
                    v-model="form.sex"
                    label="Gender"
                    :items="[
                      { text: 'Male', value: 'Male' },
                      { text: 'Female', value: 'Female' },
                    ]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    v-model="$parent.form.bvn"
                    :readonly="$parent.form.bvn !== null"
                    label="BVN"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col class="py-0 my-0" cols="12" md="12">
                  <v-menu
                    v-model="$parent.menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="event_note"
                        outlined solo
                        :value="form.dob"
                        placeholder="Date of birth"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      light
                      v-model="form.dob"
                      @change="$parent.menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <a :href="form.passport_url" target="_blank" v-if="form.passport_url" class="primary--text"><small>View</small></a>
                  <v-file-input
                    v-model="form.passport"
                    label="Upload Passport Photograph"
                    accept="image/*, application/pdf"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0 pt-2">
                <v-text-field
                  type="text"
                  v-model="form.home_address"
                  label="Address"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <a :href="form.proof_of_address_url" target="_blank" class="primary--text"><small>View</small></a>
                <v-file-input 
                  label="Upload Utility Bill"
                  v-model="form.address_file"
                  accept="image/*, application/pdf"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.mother_maiden_name"
                  label="Mother's maiden name"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.next_of_kin"
                  label="Next of Kin"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-text-field
                  type="number"
                  v-model="form.next_of_kin_contact"
                  label="Next of kin contact"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-autocomplete
                  v-model="form.state_id"
                  :items="$parent.states"
                  label="Select State"
                  @change="$parent.lgas = []; $parent.getLgas()"
                  :loading="$parent.loadStates"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-autocomplete
                  type="text"
                  v-model="form.lga_id"
                  label="Select LGA"
                  :items="$parent.lgas"
                  :loading="$parent.loadLgas"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.city"
                  label="City"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-select
                  v-model="form.id_card_type_id"
                  :items="$parent.card_types"
                  label="ID Card Type"
                  :loading="$parent.fetchIds"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-text-field
                  type="text"
                  v-model="form.id_card_number"
                  label="ID card Number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12" class="py-0 my-0">
                <a :href="form.id_card_url" target="_blank" class="primary--text"><small>View</small></a>
                <v-file-input 
                  label="Upload ID" 
                  v-model="form.card_file" 
                  accept="image/*, application/pdf"
                >
                </v-file-input>
              </v-col>
            </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0 pt-2">
                  <v-text-field
                    type="text"
                    v-model="form.guarantor"
                    label="Guarantor Name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    v-model="form.guarantor_contact"
                    label="Guarantor Phone Number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-autocomplete
                    type="text"
                    v-model="form.country_of_residence_id"
                    label="Select Country of residence"
                    :items="$parent.countries"
                    :loading="$parent.loadCountries"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12" class="py-0 my-0">
                  <v-autocomplete
                    type="text"
                    v-model="form.country_of_origin_id"
                    label="Select Country of Origin"
                    :items="$parent.countries"
                    :loading="$parent.loadCountries"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-card-actions
          class="justify-center px-0"
        >
        <v-col cols="6">
          <v-btn
            color="grey lighten-3"
            @click="step == 1 ? closeDialog() : step -= 1"
            depressed
            block
          >{{ step == 1 ? 'CLOSE' : 'Back' }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            @click="step == 3 ? updateKyc() : step += 1"
            :loading="loading"
          >{{ step == 3 ? 'Submit' : 'Next' }}</v-btn>
        </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      step: 1,
      form: {},
    }
  },

  mounted() {
    this.form = this.$parent.form
    this.$root.$on('get-states', () => {
      this.$parent.getLgas()
    })
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', [
      'kyceditrequest',
      'updatekyc',
    ]),

    openDialog(kyc) {
      this.form.first_name              = kyc.first_name
      this.form.last_name               = kyc.last_name
      this.form.middle_name             = kyc.middle_name
      this.form.sex                     = this.$parent.form.sex
      this.form.dob                     = this.$parent.form.dob
      this.form.state_id                = kyc.state_id
      this.form.lga_id                  = kyc.lga_id
      this.form.country_of_residence_id = kyc.country_of_residence_id
      this.form.country_of_origin_id    = kyc.country_of_origin_id
      this.form.guarantor               = kyc.guarantor
      this.form.guarantor_contact       = kyc.guarantor_contact
      this.form.home_address            = kyc.home_address
      this.form.mother_maiden_name      = kyc.mother_maiden_name
      this.form.next_of_kin             = kyc.next_of_kin
      this.form.next_of_kin_contact     = kyc.next_of_kin_contact
      this.form.id_card_type_id         = kyc.id_card_type_id
      this.form.city                    = kyc.city
      this.form.id_card_number          = kyc.id_card_number
      this.form.passport_url            = kyc.passport_url
      this.form.id_card_url             = kyc.id_card_url
      this.form.proof_of_address_url    = kyc.proof_of_address_url
      this.dialog                       = true
      this.$root.$emit('get-states')
    },

    closeDialog() {
      this.dialog = false
    },

    async updateKyc() {
      this.loading = true
      let formData = new FormData()

      // Ordinary data
      formData.append('middle_name', this.form.middle_name)
      formData.append('sex', this.form.sex)
      formData.append('bvn', this.form.bvn)
      formData.append('passport_photo', this.form.passport)
      formData.append('user_id', this.user.id)
      formData.append('first_name', this.form.first_name)
      formData.append('last_name', this.form.last_name)
      formData.append('dob', this.form.dob)

      // Classic data
      formData.append('home_address', this.form.home_address)
      formData.append('address', this.form.home_address)
      formData.append('address_file', this.form.address_file)
      formData.append('mother_maiden_name', this.form.mother_maiden_name)
      formData.append('next_of_kin', this.form.next_of_kin)
      formData.append('next_of_kin_contact', this.form.next_of_kin_contact)
      formData.append('state_id', this.form.state_id)
      formData.append('lga_id', this.form.lga_id)
      formData.append('city', this.form.city)
      formData.append('id_card_number', this.form.id_card_number)
      formData.append('card_file', this.form.card_file)
      formData.append('id_card_type_id', this.form.id_card_type_id)

      // Premium data
      formData.append('guarantor', this.form.guarantor)
      formData.append('guarantor_contact', this.form.guarantor_contact)
      formData.append('country_of_residence_id', this.form.country_of_residence_id)
      formData.append('country_of_origin_id', this.form.country_of_origin_id)
      formData.append('id', this.user.id)
      try {
        let response = await this.updatekyc(formData)
        this.loading = false
        this.$parent.kyc = Object.assign({}, response.data)
        this.$parent.getKyc()
        this.closeDialog()
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    }
  }
}
</script>