<template>
  <div>
    <v-dialog
      max-width="350"
      v-model="dialog"
    >
      <v-card class="pa-5">
        <div class="text-center">
          <v-icon size="50" class="pb-4" color="error darken-1">error_outline</v-icon>
        </div>
        <v-divider class="error mb-3"></v-divider>
        <!-- <v-img
          :lazy-src="document"
          :src="document"
        ></v-img> -->
        <div v-for="(message, index) in messages" :key="index">
          <div class="text-center error--text"><v-icon size="7" color="error">fiber_manual_record</v-icon> {{ message }}</div>
        </div>
        <v-card-actions
          class="d-flex justify-center"
        >
          <v-btn
            text
            class="primary"
            @click="closeDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
  },

  data() {
    return {
      dialog: false,
    }
  },

  computed: {
    messages() {
      let messages  = Object.values(this.errors)
      let errors    = messages.flat()
      return errors
    }
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },
  }
}
</script>