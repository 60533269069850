<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        class="pa-2"
      >
        <v-card-title class="justify-center primary--text">Account Types</v-card-title>
        <v-divider color="error"></v-divider>
        <v-card-subtitle class="font-weight-bold mt-4 primary--text">Ordinary Account</v-card-subtitle>
        <v-card-text style="margin-top: -1em;">
          <div>- &#8358;50,000 Maximum Withdrawal limit daily</div>
          <div>- Create Multiple Savings Accounts</div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-subtitle class="font-weight-bold mt-4 primary--text">Classic Account</v-card-subtitle>
        <v-card-text style="margin-top: -1em;">
          <div>- &#8358;150,000 Maximum Withdrawal limit daily</div>
          <div>- ATM Card</div>
          <div>- Create Multiple Savings Accounts</div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-subtitle class="font-weight-bold mt-4 primary--text">Premium Account</v-card-subtitle>
        <v-card-text style="margin-top: -1em;">
          <div>- &#8358;500,000 Withdrawal or More daily</div>
          <div>- Take Loan</div>
          <div>- Request POS</div>
          <div>- ATM Card</div>
          <div>- Create Multiple Savings Account</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="justify-end"
        >
          <v-btn
            text
            color="primary"
            @click="closeDialog()"
          >CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    openDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },
  }
}
</script>